import { useState } from 'react';
import { useRouter } from 'next/router';

import { TextBtn } from '@playbooks/interface/buttons';
import { Drop, DropHeader, DropItem, DropList, DropMenu, DropTitle } from '@playbooks/interface/drops';
import { FormGroup, FormLabel } from '@playbooks/interface/forms';
import { Span } from '@playbooks/interface/html';
import { FilterBtn } from 'molecules/buttons';
import { BtnGroupFilter, CurrencyFilter } from 'molecules/filters';

const BountyMoreFilters = ({ params, setParams, tailwind }) => {
	const [open, setOpen] = useState(false);
	const [ref, setRef] = useState(null);
	const router = useRouter();

	// Computed
	const statuses = ['all', 'active', 'closed', 'finished'];
	const tiers = ['all', 'free', 'pro', 'plus', 'premiere', 'custom'];
	const variants = ['all', 'starter', 'recipe', 'template', 'theme', 'app'];
	const revenues = ['10000', '5000', '1000', '500', '250', '0'];

	const count = Object.keys(router.query)
		.filter(key => ['status', 'variant', 'tier', 'meta.netRevenue'].includes(key))
		.filter(key => router.query[key]).length;

	// Methods
	const onRouterFilter = filter => {
		const query = { ...router.query, ...filter };
		router.push({ query }, undefined, { shallow: true });
	};

	const onRouterClear = () => {
		const query = {};
		Object.keys(router.query)
			.filter(key => key !== 'status')
			.filter(key => key !== 'tier')
			.filter(key => key !== 'variant')
			.filter(key => key !== 'meta.netRevenue')
			.map(key => (query[key] = router.query[key]));
		router.push({ query }, undefined, { shallow: true });
	};

	const onToggle = () => setOpen(!open);
	const onClose = () => setOpen(false);

	// Render
	return (
		<Drop ref={setRef} open={open} onClose={onClose} {...tailwind?.drop}>
			<Span display='hidden lg:block'>
				<FilterBtn type='icon' count={count} active={open} onClick={onToggle} />
			</Span>
			<Span display='block lg:hidden'>
				<FilterBtn type='drop' count={count} active={open} onClick={onToggle} />
			</Span>

			<DropMenu ref={ref} open={open} spacing='right-0 mt-4' width='w-inherit lg:w-max'>
				<DropHeader border='border-b' display='flex-between' spacing='p-4'>
					<DropTitle>More Filters</DropTitle>
					<TextBtn size='' fontSize='text-sm' textDecoration='h:underline' onClick={onRouterClear}>
						Clear
					</TextBtn>
				</DropHeader>

				<DropList space='space-y-4' spacing='p-4'>
					<DropItem border='border-b'>
						<FormGroup>
							<FormLabel display='block'>Status</FormLabel>
							<BtnGroupFilter
								value={router.query.status || 'all'}
								options={statuses}
								onSelect={status => onRouterFilter({ status: status === 'all' ? '' : status })}
								tailwind={{ span: { width: 'w-[25%]' } }}
							/>
						</FormGroup>
					</DropItem>

					<DropItem border='border-b'>
						<FormGroup>
							<FormLabel display='block'>Project type</FormLabel>
							<BtnGroupFilter
								value={router.query.variant || 'all'}
								options={variants}
								onSelect={variant => onRouterFilter({ variant: variant === 'all' ? '' : variant })}
								tailwind={{ span: { width: 'w-[20%]' } }}
							/>
						</FormGroup>
					</DropItem>

					<DropItem>
						<FormGroup>
							<FormLabel display='block'>Amount Raised</FormLabel>
							<CurrencyFilter
								value={router.query['meta.netRevenue'] || '0'}
								options={revenues}
								onSelect={revenue => onRouterFilter({ 'meta.netRevenue': revenue })}
							/>
						</FormGroup>
					</DropItem>
				</DropList>
			</DropMenu>
		</Drop>
	);
};

export { BountyMoreFilters };
